<template>
    <div class="TrainData" v-if="detailData">
        <StudentTopNavBar :isBack="true"></StudentTopNavBar>
        <main class="main">
            <el-scrollbar style="height: 100%;">
                <div class="container">
                    <div class="left-container">
                        <div class="base-info">
                            <div class="item" v-for="item in baseInfo">
                                <div class="header">
                                    <i class="iconfont" :style="{ backgroundColor: item.color }" v-html="item.icon"></i>
                                    <div class="label">
                                        <span>{{ item.label }}</span>
                                        <span class="hint">{{ item.hint }}</span>
                                    </div>
                                </div>
                                <div class="data">{{ item.data }}</div>
                            </div>
                        </div>
                        <div class="chart-list">
                            <div class="sales-chart chart-item" v-for="item in [salesChart, profitChart]">
                                <div class="title">{{ item.label }}</div>
                                <div class="chart content">
                                    <MyEcharts v-if="item.data" :ref="item.id" :id="item.id" :options="item.data">
                                    </MyEcharts>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sales-list content">
                        <div class="title">商品销售排行</div>
                        <div class="table-data">
                            <div class="th">
                                <div class="rank">名次</div>
                                <div class="goods">商品</div>
                                <div class="sales">销量</div>
                            </div>
                            <div class="tr">
                                <div class="item" v-for="item, index in detailData.rank">
                                    <div class="rank">{{ index + 1 }}</div>
                                    <div class="goods">
                                        <div>{{ item.good_info.name }}</div>
                                    </div>
                                    <div class="sales">{{ item.sales_volume }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-scrollbar>
        </main>
    </div>
</template>

<script>
import StudentTopNavBar from "components/student/periodSettlement/StudentTopNavbar.vue"
import MyEcharts from "components/common/MyEcharts.vue"

export default {
    name: 'TrainData',
    components: {
        StudentTopNavBar,
        MyEcharts
    },
    props: {},
    data() {
        return {
            salesChart: {
                label: "销售额数据",
                id: "sales",
                color: "#34C759",
                data: null
            },
            profitChart: {
                label: "利润数据",
                id: "profit",
                color: "#F7BA1E",
                data: null
            },
            baseInfo: {
                total_cost: {
                    icon: "\ue671",
                    label: "总成本",
                    hint: "Number of categories",
                    color: "#ff9500",
                    data: 0
                },
                total_profit: {
                    icon: "\ue6f7",
                    label: "总利润",
                    hint: "Commodity inventory",
                    color: "#34c759",
                    data: 0
                },
                total_revenue: {
                    icon: "\ue66e",
                    label: "总收入",
                    hint: "Quantity on shelves",
                    color: "#0a84ff",
                    data: 0
                }
            },
            periodList: ["第一周期", "第二周期", "第三周期"],
            detailData: null
        }
    },
    computed: {},
    methods: {
        validateRes(res) {
            const valid = res.code == 200;
            valid || this.$message.error(res.msg);
            return valid;
        },
        getDetail() {
            // const competition_id = localStorage.getItem("com_id");
            // if (!competition_id) return;
            this.$http.axiosPost(this.$api.studentStoreSalesSummary, { competition_id: null }, res => {
                if (!this.validateRes(res)) return;
                Object.keys(this.baseInfo).forEach(key => {
                    this.baseInfo[key].data = res.data[key];
                })
                this.detailData = res.data;
                let salesData = [];
                let profitData = [];
                [1, 2, 3].forEach(item => {
                    const data = res.data.cycle[item];
                    salesData.push(data.sales_volume);
                    profitData.push(data.total_profit);
                })
                this.salesChart.data = this.getChartOptions(salesData, this.salesChart.color);
                this.profitChart.data = this.getChartOptions(profitData, this.profitChart.color);
            }, err => {
                console.log(err);
            })
        },
        getChartOptions(data, color) {
            return {
                xAxis: {
                    type: 'category',
                    data: ["第一周期", "第二周期", "第三周期"]
                },
                yAxis: {
                    type: 'value'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        // type: 'shadow'
                    }
                },
                series: [{
                    data,
                    type: 'bar',
                    barWidth: "20%",
                    color
                }]
            }
        },
        resize() {
            this.$refs.sales && this.$refs.sales[0] && this.$refs.sales[0].resize()
            this.$refs.profit && this.$refs.sales[0] && this.$refs.profit[0].resize()
        }
    },
    created() {
        this.getDetail();
        window.addEventListener("resize", this.resize);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.resize)
    },
}
</script>

<style scoped lang="scss">
.TrainData {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100vh;
    width: 100vw;
    overflow: hidden;

    .main {
        flex: 1;
        overflow: hidden;
        background-color: #f8f9fb;

        .content {
            border-radius: 4px;
            box-shadow: 0px 0px 32px 2px rgba(0, 0, 0, 0.1);
            background-color: #fff;
        }

        .container {
            box-sizing: border-box;
            display: flex;
            width: 100%;
            padding: 2% 3%;
            overflow: hidden;

            .left-container {
                flex: 1.2;

                .base-info {
                    display: grid;
                    margin-right: 30px;
                    column-gap: 10px;
                    grid-template-columns: 1fr 1fr 1fr;

                    .item {
                        @extend .content;
                        padding: 0px 10%;
                        height: 180px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;

                        .header {
                            display: flex;
                            align-items: center;
                            font-size: 16px;

                            .iconfont {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 50px;
                                height: 50px;
                                margin-right: 10px;
                                font-size: 26px;
                                color: #fff;
                                background-color: #FF9500;
                                border-radius: 10px;
                            }

                            .label {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-around;
                                height: 100%;

                                .hint {
                                    color: #767676;
                                    font-size: 14px;
                                }
                            }
                        }

                        .data {
                            font-size: 34px;
                        }
                    }
                }

                .chart-list {
                    display: flex;
                    box-sizing: border-box;
                    height: 430px;
                    margin-top: 30px;
                    margin-right: 30px;

                    .chart-item {
                        flex: 1;
                        display: flex;
                        flex-direction: column;

                        &:first-child {
                            margin-right: 30px;
                        }

                        .title {
                            margin-bottom: 10px;
                            font-size: 16px;
                            font-weight: bold;
                        }

                        .chart {
                            flex: 1;
                        }
                    }
                }
            }


            .sales-list {
                flex: 0.5;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                padding: 2%;
                overflow: hidden;

                .title {
                    margin-bottom: 15px;
                }

                .table-data {
                    flex: 1;
                    overflow: hidden;

                    .flex-content {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .rank {
                        width: 58px;
                    }

                    .goods {
                        flex: 1;
                    }

                    .sales {
                        width: 98px;
                    }

                    .rank,
                    .goods,
                    .sales {
                        @extend .flex-content;
                    }

                    .th {
                        display: flex;
                        height: 40px;

                        div:not(:first-child) {
                            margin-left: 4px;
                        }

                        div {
                            background-color: #F8F9FB;
                        }
                    }

                    .tr {
                        width: 100%;
                        overflow: hidden;

                        .item {
                            display: flex;
                            height: 50px;
                            width: 100%;
                            margin-top: 4px;
                            overflow: hidden;

                            &:first-child {
                                background-color: #fff8e5;
                            }

                            &:nth-child(2) {
                                background-color: #f5faff;
                            }

                            &:nth-child(3) {
                                background-color: #fffcfa;
                            }

                            .goods {
                                box-sizing: border-box;
                                justify-content: flex-start;
                                padding: 0 10px;
                                overflow: hidden;

                                div {
                                    width: 100%;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                }
                            }

                            &:hover {
                                cursor: pointer;
                                background-color: #f8f8f8;
                            }
                        }
                    }
                }
            }
        }
    }
}

::v-deep .el-scrollbar__wrap {
    overflow-x: hidden !important;
}</style>
