<template>
    <div class="myECharts" :id="id"></div>
</template>

<script>
import * as echarts from 'echarts';

export default {
    name: 'MyEcharts',
    components: {},
    props: {
        id: String,
        options: Object
    },
    data() {
        return {
            container: null
        }
    },
    computed: {},
    methods: {
        init() {
            const elm = document.getElementById(this.id);
            if(!elm) {
                this.$message.error("未获取到容器元素");
                return void 0;
            };
            const temp = echarts.init(elm);
            temp.setOption(this.options, true);
            this.container = temp;
        },
        resize() {
            this.container.resize();
        }
    },
    mounted() {
        this.init()
    },
}
</script>

<style scoped lang="scss">
.myECharts {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}
</style>
